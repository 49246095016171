import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/AuthContext';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import Home from './pages/Home';
import TopicPage from './pages/TopicPage.jsx';
import Login from './components/Login';
import { collection, getDocs } from 'firebase/firestore';
import { db } from './api/firebaseConfigs.js';
import './App.css';

const AppContent = () => {
  const location = useLocation();
  const [filteredContent, setFilteredContent] = useState([]);
  const { user } = useAuth();

  useEffect(() => {
    const fetchContentData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "Devhub-contentMetaData"));
        const data = querySnapshot.docs.map(doc => doc.data());
        console.log("Fetched content data: ", data);
        setFilteredContent(data);
      } catch (error) {
        console.error("Error fetching content data: ", error);
      }
    };

    fetchContentData();
  }, []);

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="flex">
      <Sidebar className="sidebar" />
      <div className="flex-grow">
        <Navbar onSearch={setFilteredContent} className="navbar" />
        <main className="p-8 container">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/topic/:id" element={<TopicPage />} />
          </Routes>
          {location.pathname === '/' && (
            <div className="content-list">
              {filteredContent.map(item => (
                item ? (
                  <div key={item.id} className="p-4 border-b border-gray-200">
                    <a href={`/topic/${item.id}`} className="text-2xl text-blue-500">
                      {item.title}
                    </a>
                    <p className="text-gray-700">{item.body}</p>
                  </div>
                ) : null
              ))}
            </div>
          )}
        </main>
      </div>
    </div>
  );
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/*" element={<AppContent />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
