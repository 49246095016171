import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../api/firebaseConfigs';
import { useAuth } from '../../context/AuthContext';
import './Sidebar.css';

const Sidebar = () => {
  const [contentData, setContentData] = useState([]);
  const { logout } = useAuth();

  useEffect(() => {
    const fetchContentData = async () => {
      const querySnapshot = await getDocs(collection(db, "Devhub-contentMetaData"));
      const data = querySnapshot.docs.map(doc => doc.data());
      setContentData(data);
    };

    fetchContentData();
  }, []);

  return (
    <div className="w-48 h-screen bg-gray-100 p-4 flex flex-col justify-between">
      <nav className="flex flex-col space-y-2">
        {contentData.map(item => (
          <Link key={item.id} to={`/topic/${item.id}`} className="text-gray-800 hover:bg-gray-200 p-2 rounded">
            {item.title}
          </Link>
        ))}
      </nav>
      <button
        onClick={logout}
        className="text-white bg-red-500 hover:bg-red-700 p-2 rounded mt-4"
      >
        Logout
      </button>
    </div>
  );
};

export default Sidebar;
