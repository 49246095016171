import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../api/firebaseConfigs';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import './TopicPage.css';

const TopicPage = () => {
  const { id } = useParams();
  const [content, setContent] = useState("");

  useEffect(() => {
    const fetchContent = async () => {
      const docRef = doc(db, "Devhub", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        console.log(data);

        // Get the content
        let content = data.content;

        // Replace all \n with actual newlines
        content = content.replace(/\\n/g, '\n');
        
        // Remove the beginning and ending double quotes if they exist
        if (content.startsWith('"') && content.endsWith('"')) {
          content = content.slice(1, -1);
        }

        setContent(content);
      } else {
        setContent("# Content Not Found\nThe requested content does not exist.");
      }
    };

    fetchContent();
  }, [id]);

  return (
    <div className="container mx-auto p-8">
      <ReactMarkdown remarkPlugins={[remarkGfm]} className="prose prose-lg">
        {content}
      </ReactMarkdown>
    </div>
  );
};

export default TopicPage;
