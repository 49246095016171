import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Fuse from 'fuse.js';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../api/firebaseConfigs';
import './Navbar.css';

const Navbar = ({ onSearch }) => {
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  const [query, setQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [contentData, setContentData] = useState([]);
  const searchRef = useRef(null);

  useEffect(() => {
    const fetchContentData = async () => {
      const querySnapshot = await getDocs(collection(db, "Devhub-contentMetaData"));
      const data = querySnapshot.docs.map(doc => doc.data());
      setContentData(data);
      onSearch(data);
    };

    fetchContentData();
  }, [onSearch]);

  useEffect(() => {
    // Clear the query and search results when the location changes
    setQuery('');
    setSearchResults([]);
    onSearch(contentData);
  }, [location, onSearch, contentData]);

  useEffect(() => {
    if (query === '') {
      onSearch(contentData);
      setSearchResults([]);
    }
  }, [query, onSearch, contentData]);

  const fuse = new Fuse(contentData, {
    keys: ['title', 'body'],
    includeScore: true,
  });

  const handleSearch = (e) => {
    setQuery(e.target.value);
    const results = e.target.value ? fuse.search(e.target.value).map(result => result.item) : contentData;
    onSearch(results);
    setSearchResults(results);
  };

  const handleBlur = (e) => {
    // Check if the new focused element is within the search bar or results
    if (searchRef.current && !searchRef.current.contains(e.relatedTarget)) {
      setIsSearchActive(false);
      setSearchResults([]);
    }
  };

  return (
    <nav className="bg-gray-800 p-4 flex justify-between items-center">
      <Link to="/" className="text-white text-xl font-bold">DevHub</Link>
      <div className="relative ml-auto" ref={searchRef}>
        <input
          type="text"
          value={query}
          onChange={handleSearch}
          onFocus={() => setIsSearchActive(true)}
          onBlur={handleBlur}
          className="w-64 p-2 border border-gray-300 rounded-extra"
          placeholder="Search..."
        />
        {!isHomePage && isSearchActive && query && (
          <div className="absolute mt-2 w-full bg-white border border-gray-300 rounded-extra shadow-lg z-10">
            {searchResults.map(item => (
              <Link key={item.id} to={`/topic/${item.id}`} className="block p-2 hover:bg-gray-200">
                {item.title}
              </Link>
            ))}
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
