import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../api/firebaseConfigs';
import './Home.css';

const Home = () => {
  const [contentData, setContentData] = useState([]);

  useEffect(() => {
    const fetchContentData = async () => {
      const querySnapshot = await getDocs(collection(db, "Devhub-contentMetadata"));
      const data = querySnapshot.docs.map(doc => doc.data());
      setContentData(data);
    };

    fetchContentData();
  }, []);

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-4xl font-bold">Welcome to DevHub</h1>
      <p className="mt-4 text-lg">Your internal hub for all things development.</p>
      <div>
        {contentData.map(item => (
          <div key={item.id} className="p-4 border-b border-gray-200">
            <a href={`/topic/${item.id}`} className="text-2xl text-blue-500">
              {item.title}
            </a>
            <p className="text-gray-700">{item.body}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Home;
